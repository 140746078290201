@font-face {
    font-family: ultra;
    font-weight: 400;
    src: url(../public/OpenSans-VariableFont_wdth\,wght.ttf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ultra;
}


:root {
    --height-below-navbar: 85vh;
}

.divider {
    width: 50px;
    height: auto;
    display: inline;
}

.root {
    display: flex;
}

body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background-color: #f6f6f6;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}


.table-cell-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 20vh;
    text-align: center;
}

.main-page-title {
    text-align: center;
    display: flex;
    /* font-size: clamp(2rem, 3vw, 4rem); */
    font-size: clamp(3.5rem, 2vw, 5rem);
    /* color: #4c4c4c; */
    color: black;
    flex: 1;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.primary-text {
    display: flex;
    font-size: clamp(1rem, vw, 1.5rem);
    width: 80%;
    max-height: 100%;
    /* color: #6a6a6a; */
    color: "black";
    flex: 2;
    align-items: center;
    justify-items: center;
}

.logo-image {
    display: flex;
    flex: 1;
}

.primary-subheading {
    font-weight: 700;
    font-size: 1.7rem;
    /* color: #FF9E10; */
    color: #e91516;
    /* color: #E4920E; */
}

.download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.download-button:hover {
    transform: scale(1.05);
}

.footer-icons {
    margin-top: 1.5rem;
    flex-direction: row;
    display: flex;
    flex: 1;
    /* align-items: ; */
    align-items: flex-start;
    /* justify-content: space-evenly; */
    justify-content: center;
    gap: even;
}

.footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    /* color: #FF9E10; */
    color: #314403;
}

.footer-section-columns {
    display: flex;
    flex-direction: column;
    flex: 2;
    text-align: center;
    justify-content: space-evenly;
}

.primary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
}

.footer-section-heading {
    margin: 0.25rem 0rem;
    font-weight: 650;
    color: black ! important;
    font-size: large;
    /* cursor: pointer; */
}

.footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
}

.footer-section-columns :hover {
    color: #6B8C07;
}



.subscriptions-section-top p {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 600px !important;
    vertical-align: middle;
}

.subscriptions-section-bottom {
    transition: transform 0.3s ease;
}

.subscriptions-section-bottom :hover {
    transform: scale(1.05);
}

/* .subscriptions-section-top h1 {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 700px !important;
} */

.subscriptions-section-info h2 {
    margin: 1rem 0rem;
    display: "inline-block"
}


.testimonial-section-bottom {
    /* margin-top: 2rem; */
    /* background-color: white; */
    /* padding: 1.5rem 2rem; */
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    /* border-radius: 2rem; */
    flex-direction: row;
    /* text-align: center; */
    gap: 1rem;
}

.testimonial-card {
    margin-top: 2rem;
    background-color: white;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 2rem;
    flex-direction: column;
    text-align: center;
}

.testimonial-section-bottom {
    display: flex;
    max-height: 80%;
}

.testimonial-section-bottom p {
    font-weight: 700;
    color: #515151;
    max-width: 500px;
    font-size: 1.1rem;
    margin: 2rem 0rem;
}

.testimonials-stars-container svg {
    margin: 0rem 0.25rem;
    font-size: 1.5rem;
    color: #fe9e0d;
}